<template>
  <div class="About">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>当サイトについて</v-toolbar-title>
      </v-toolbar>
      <p>当サイトは個人運営サイトですので、掲載されている各サイトや製造業者等の第三者への迷惑になる行為は厳に謹んでいただきますよう、よろしくお願いいたします。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Top' }">
            <v-list-item-content>トップに戻る</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
export default{
  name: 'About'
}
</script>
